import * as React from 'react'
import Typography from '@mui/material/Typography'
import VerticalCopropriete from './VerticalCopropriete'
export default function Copropriete() {
  return (
    <>
      <VerticalCopropriete main={<Typography color='#84ffff'>lorem</Typography>} />
    </>
  )
}
