import axios from 'axios'
import { useState } from 'react'
const baseUrl = 'http://62.169.30.172:8001/api/photos'

const getAll = () => {
  const request = axios.get(baseUrl)
  return request.then((response) => response.data)
}
const createInd = async (newObject, setProgress) => {
  const uploadData = new FormData()
  if (newObject.piecePath[0] !== undefined) {
    uploadData.append('PiecePath', newObject.piecePath[0])
    console.log('aaaaaaaa')
  }
  const headers = {
    'Content-Type': 'multipart/form-data',
  }

  let responseArray = []
  console.log('FDGFGFDA', newObject)

  try {
    const piecereq = await axios.post(baseUrl + '/uploadInd/', uploadData, {
      headers: headers,
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100
        setProgress(progress) // Update progress
      },
    })
    responseArray.push(piecereq.data)
  } catch (error) {
    console.error(error)
  }

  return responseArray
}

const create = async (newObject, setProgress) => {
  const uploadData = new FormData()
  if (newObject.plan[0] !== undefined) {
    uploadData.append('PlanPath', newObject.plan[0])
    console.log('pppppp')
  }
  if (newObject.cont[0] !== undefined) {
    uploadData.append('ContPath', newObject.cont[0])
    console.log('cccccccccc')
  }
  if (newObject.taba[0] !== undefined) {
    uploadData.append('TabPath', newObject.taba[0])
    console.log('aaaaaaaa')
  }

  if (newObject.hasOwnProperty('titreName')) {
    uploadData.append('namePath', newObject.titreName)
  }
  const headers = {
    'Content-Type': 'multipart/form-data',
  }

  let responseArray = []
  let progressArray = [0, 0, 0];
  console.log('FDGFGFDA', newObject)
  const updateOverallProgress = () => {
    const totalProgress = progressArray.reduce((acc, progress) => acc + progress, 0);
    const overallProgress = totalProgress / 3; // Average of the three uploads
    setProgress(overallProgress); // Update overall progress state
  };
  try {
    const planreq = await axios.post(baseUrl + '/uploadplan/', uploadData, {
      headers: headers,
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        progressArray[0] = progress; // Update progress of the first upload
        updateOverallProgress(); // Update overall progress
      },
    });
    responseArray.push(planreq.data);
  } catch (error) {
    console.error(error);
  }

  try {
    const contreq = await axios.post(baseUrl + '/uploadcont/', uploadData, {
      headers: headers,
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        progressArray[1] = progress; // Update progress of the second upload
        updateOverallProgress(); // Update overall progress
      },
    });
    responseArray.push(contreq.data);
    console.log("responseArray",responseArray)
  } catch (error) {
    console.error(error);
  }

  try {
    const tabreq = await axios.post(baseUrl + '/uploadtaba/', uploadData, {
      headers: headers,
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        progressArray[2] = progress; // Update progress of the third upload
        updateOverallProgress(); // Update overall progress
      },
    });
    responseArray.push(tabreq.data);
  } catch (error) {
    console.error(error);
  }

  return responseArray;
};
const createcop = async (newObject, setProgress) => {
  const uploadData = new FormData()

  if (newObject.copro[0] !== undefined) {
    for (let i = 0; i < newObject.copro.length; i++) {
      uploadData.append(`CopPath${i}`, newObject.copro[i])
    }
  }

  if (newObject.hasOwnProperty('titreName')) {
    uploadData.append('namePath', newObject.titreName)
  }
  if (newObject.hasOwnProperty('titreDu')) {
    uploadData.append('nameDuPath', newObject.titreDu)
  }
  if (newObject.hasOwnProperty('titreAu')) {
    uploadData.append('nameAuPath', newObject.titreAu)
  }

  const headers = {
    'Content-Type': 'multipart/form-data',
  }

  let responseArray = []

  try {
    const copreq = await axios.post(baseUrl + '/uploadcop/', uploadData, {
      headers: headers,
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100
        setProgress(progress) // Update progress
      },
    })
    responseArray.push(copreq.data)
  } catch (error) {
    console.error(error)
  }

  return responseArray
}
const update = (id, newObject) => {
  const request = axios.put(`${baseUrl}/${id}`, newObject)
  return request.then((response) => response.data)
}

const axios_del = (id) => {
  const request = axios.delete(`${baseUrl}/${id}`)
  return request.then((response) => response.data)
}

export { getAll, create, update, axios_del, createcop, createInd }
